<template>
  <div class="container">
    <div class="form">
      <div class="row" style="margin-top: 24px">
        <div class="label">评价对象</div>
        <div>{{ type=='0'?'在线指导':type=='1'?'事件上报':'民事调解'}}</div>
      </div>

      <div class="row">
        <div class="label">标题</div>
        <div>{{ title }}</div>
      </div>

      <div class="row col">
        <div class="label must">评分</div>
        <div>
          <van-rate v-model="form.grade" :readonly="isGrade" color="#ffd21e" :size="28" :count="10" />
        </div>
      </div>

      <div class="row col" style="margin-bottom: 12px">
        <div class="label">评价</div>
        <van-field v-model="form.gradeOpinion" :readonly="isGrade" rows="5" autosize type="textarea" />
      </div>
    </div>

    <van-button v-if="!isGrade" type="info" block @click="subForm">提交</van-button>
  </div>
</template>
<script>
import { setStar, setStar2, getAskDetail, getDetail } from '@/api/eventApi'
export default {
  data() {
    return {
      form: {
        grade: 0,
        gradeOpinion: '',
        id: '',
      },
      type: '',
      title: '',
      isGrade: false
    }
  },
  created() {
    // this.isGrade = this.$route.isGrade==1?true:false
    this.type = this.$route.query.gradeType
    this.form.id = this.$route.query.id
    this.getTitle(this.type)
  },
  mounted() {},
  methods: {
    subForm() {
      // let data = {

      // }
      if(!this.form.grade || this.form.grade == 0) {
        return this.$toast({
            type: 'warning',
            message: '请评分',
            duration: 1000,
          })
      }
      this.$store.commit('SET_IS_LOADING', true)
      this.form.isGrade = 1
      if(this.type=='0') {
        setStar(this.form).then(res => {
          this.$store.commit('SET_IS_LOADING', false)
          if(res.code == 200) {
            this.$toast({
              type: 'success',
              message: '感谢您的评价,您的满意是我前进的动力',
              duration: 2000,
              onClose: () => {
                this.$router.go(-1)
              }
            })
          } else {
            this.$toast({
              type: 'error',
              message: res.msg,
              duration: 1000,
            })
          }
        })
      } else {
        setStar2(this.form).then(res => {
          this.$store.commit('SET_IS_LOADING', false)
          if(res.code == 200) {
            this.$toast({
              type: 'success',
              message: '感谢您的评价,您的满意是我前进的动力',
              duration: 2000,
              onClose: () => {
                this.$router.go(-1)
              }
            })
          } else {
            this.$toast({
              type: 'error',
              message: res.msg,
              duration: 1000,
            })
          }
        })
      }

    },
    getTitle(type) {
      this.$store.commit('SET_IS_LOADING', true)
      if(type == '0') {
        getAskDetail({
          id: this.form.id
        }).then(res => {
          this.$store.commit('SET_IS_LOADING', false)
          this.title = res.data.title
          this.isGrade = res.data.isGrade === 1?true:false
          if(this.isGrade) {
            this.form.grade = res.data.grade
            this.form.gradeOpinion = res.data.gradeOpinion
          }
        })
      } else {
        getDetail({
          id: this.form.id
        }).then(res => {
          this.$store.commit('SET_IS_LOADING', false)
          this.title = res.data.title
          this.isGrade = res.data.isGrade === 1?true:false
          if(this.isGrade) {
            this.form.grade = res.data.grade
            this.form.gradeOpinion = res.data.gradeOpinion
          }
        })
      }
    }
  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
}
.row {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 12px;
  // align-items: center;
  .van-field {
    padding: 2px 4px;
  }
  &.row2 {
    align-items: center;
    .label {
      width: 100px;
    }
  }
  &.col {
    flex-direction: column;
    align-items: start;
    .van-field {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      /* height: 100px; */
    }
    .label {
      margin-bottom: 10px;
    }
  }
  .label {
    width: 65px;
    flex-shrink: 0;
  }
  .label.must {
    position: relative;
  }
  .label.must::after {
    content: '*';
    color: #e10602;
    font-size: 18px;
    position: absolute;
    left: -8px;
    top: 1px;
  }
}
.form {
  min-height: calc(100vh - 24px - 46px - 56px);
}
.row.start {
  align-items: start;
}
::v-deep .row .van-uploader__upload {
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 5px;
}
.item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 5px;
}
.van-field {
  border: 1px solid #eeeeee;
  padding: 5px;
  border-radius: 5px;
}
</style>
